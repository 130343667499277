jQuery(document).ready(function() {

    // Columns with same height
    jQuery('.col-twins').matchHeight({
        byRow: false,
        property: 'height',
        target: null,
        remove: false
    });

    // Columns with same height
    jQuery('.col-portfolio-one').matchHeight({
        byRow: false,
        property: 'height',
        target: null,
        remove: false
    }); 
     
    // Columns with same height
    jQuery('.service-col').matchHeight({
        byRow: true,
        property: 'height',
        target: null,
        remove: false
    }); 


    // slide down mobile header
    setTimeout(function() {
        TweenLite.to(jQuery('.mobile-bar'), 0 , {ease: Power4.easeInOut, y: '-100px'});
        TweenLite.to(jQuery('.mobile-bar'), 1 , {delay: 0.3, ease: Power4.easeInOut, y: '0', opacity: 1});
    }, 100);

    // hamburger menu icon toggle
    var trigger = jQuery('#hamburger'),
        isClosed = false;

    trigger.click(function () {
      burgerTime();
      mobileToggle(trigger)
    });

    function burgerTime() {
      if (isClosed == true) {
        trigger.removeClass('is-open');
        trigger.addClass('is-closed');
        isClosed = false;
      } else {
        trigger.removeClass('is-closed');
        trigger.addClass('is-open');
        isClosed = true;
      }
    }

    // mobile menu animation
    function mobileToggle(trigger) {

        var $this = trigger,
            $mobilemenu = jQuery("#menu-wrapper-mobile"),
            $mobilebar = jQuery("#mobile-bar");

        
        if(!$this.hasClass("closed")) {

            jQuery('body').removeClass('menu-open');
                        
            jQuery(jQuery("#mobile-quicklinks .quicklink").get().reverse()).each(function(i) { 
                TweenLite.to(jQuery(this), 0.3 * i, {ease: Power4.easeInOut, opacity:0});
            });

            jQuery(jQuery("#mobile-menu li").get().reverse()).each(function(i) { 
                TweenLite.to(jQuery(this), 0.3 * i, {delay:0.7, ease: Power4.easeInOut, opacity:0});
            });

            
            TweenMax.to($mobilemenu,1,{ease: Power4.easeInOut, height:0});

            $this.addClass('closed');
            
        } else {

            jQuery('body').addClass('menu-open');

            jQuery("#mobile-menu li").each(function(i) {
                TweenLite.to(jQuery(this), 0.3 * i, {ease: Power4.easeInOut, opacity:1});
            });

            jQuery("#mobile-quicklinks .quicklink").each(function(i) {
                TweenLite.to(jQuery(this), 0.3 * i, {delay:0.7, ease: Power4.easeInOut, opacity:1});
            });

            
            TweenMax.set($mobilemenu,{height:jQuery(window).height() - jQuery('.mobile-bar').height()});
            TweenMax.from($mobilemenu,0.5,{height:0,ease:Power4.easeInOut});

            $this.removeClass('closed');
        }

    };


    jQuery('.sub-menu li a').click(function () {
        jQuery('#hamburger').click();
    });

    jQuery('.services-loop li').click(function (){

        var target = "#"+jQuery(this).data('hash');
        var $target = jQuery(target);

        jQuery('html, body').stop().animate({
            'scrollTop': $target.offset().top
        }, 900, 'swing', function () {
            window.location.hash = target;
        });
        
    });

    // Animate to div if url has hash
    var hash = window.location.hash;
    if(hash.length) {
        jQuery("html, body").animate({ scrollTop: jQuery(hash).offset().top }, 1500);
    }
    
 
    // Mobile submenu toggle
    // jQuery('#mobile-menu .menu-item-has-children a').click(function (e) {

    //     e.preventDefault();
    //     var $this = jQuery(this),
    //         $submenu = $this.siblings(".sub-menu");
    //     if(!$this.hasClass("toggle")) {

    //         jQuery("li", $submenu).each(function(i) {
    //             TweenLite.to(jQuery(this), 0.2 * i, {delay: 0.5, ease: Power4.easeInOut, opacity:1});
    //         });
    //         TweenMax.set($submenu,{height:'auto'});
    //         TweenMax.from($submenu,0.5,{height:0,ease:Power4.easeInOut});

            
    //         $this.addClass('toggle');

    //     } else {

    //         jQuery(jQuery("li", $submenu).get().reverse()).each(function(i) { 
    //             TweenLite.to(jQuery(this), 0.2 * i, {ease: Power4.easeInOut, opacity:0});
    //         });
    //         TweenMax.to($submenu,0.5,{ease: Power4.easeInOut, height:0});

    //         $this.removeClass('toggle');
            
    //     }
    // });

});


